import {
  createUnifiedTheme,
  createBaseThemeOptions,
  genPageTheme,
  palettes,
} from '@backstage/theme';

const quantumLight = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#E6005C',
      },
      secondary: {
        main: '#E6005C',
      },
      error: {
        main: '#8c4351',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: '#FAF9F6',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#565a6e',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      navigation: {
        background: '#171d22',
        indicator: '#8f5e15',
        color: '#d5d6db',
        selectedColor: '#E6005C',
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: 'Arial, sans-serif',
      h1: {
        fontSize: 54,
        fontWeight: 700,
        marginBottom: 10,
      },
      h2: {
        fontSize: 40,
        fontWeight: 700,
        marginBottom: 8,
      },
      h3: {
        fontSize: 32,
        fontWeight: 700,
        marginBottom: 6,
      },
      h4: {
        fontWeight: 700,
        fontSize: 28,
        marginBottom: 6,
      },
      h5: {
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 4,
      },
      h6: {
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 2,
      },
    },
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#171d22'], shape: 'none' }),
  },
  components: {
    BackstageHeader: {
      styleOverrides: {
        subtitle: {
          margin: 10,
          display: 'none',
        },
        type: {
          margin: 10,
          display: 'flex',
        },
        leftItemsBox: {
          display: 'flex',

          alignItems: 'center',
        },
        rightItemsBox: {
          alignContent: 'stretch',
          alignItems: 'center',
          justifyContent: 'center',
        },
        header: () => ({
          display: 'flex',
          width: 'auto',
          boxShadow: '0 0 4px 2px rgba(20, 20, 20, 0.3)',
          height: '28px',
          backgroundColor: '#171d22',
          backgroundSize: 'cover',
        }),
        title: {
          marginLeft: 4,
          fontSize: 18,
          marginBottom: 0,
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: {
          marginTop: 4,
          lineHeight: 1,
        },
        value: {
          lineHeight: 1.5,
        },
      },
    },
  },
});

const quantumDark = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#e91a6c',
      },
      secondary: {
        main: '#e91a6c',
      },
      error: {
        main: '#ff1313',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: '#333334',
        paper: '#424242',
      },
      banner: {
        info: '#34548a',
        error: '#ff1313',
        text: '#343b58',
        link: '#565a6e',
      },
      errorBackground: '#ff1313',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      navigation: {
        background: '#171d22',
        indicator: '#8f5e15',
        color: '#d5d6db',
        selectedColor: '#E6005C',
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: 'Arial, sans-serif',
      h1: {
        fontSize: 54,
        fontWeight: 700,
        marginBottom: 10,
      },
      h2: {
        fontSize: 40,
        fontWeight: 700,
        marginBottom: 8,
      },
      h3: {
        fontSize: 32,
        fontWeight: 700,
        marginBottom: 6,
      },
      h4: {
        fontWeight: 700,
        fontSize: 28,
        marginBottom: 6,
      },
      h5: {
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 4,
      },
      h6: {
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 2,
      },
    },
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#171d22'], shape: 'none' }),
  },
  components: {
    BackstageHeader: {
      styleOverrides: {
        subtitle: {
          margin: 10,
          display: 'none',
        },
        type: {
          margin: 10,
          display: 'flex',
        },
        leftItemsBox: {
          display: 'flex',

          alignItems: 'center',
        },
        rightItemsBox: {
          alignContent: 'stretch',
          alignItems: 'center',
          justifyContent: 'center',
        },
        header: () => ({
          display: 'flex',
          width: 'auto',
          height: '28px',
          backgroundColor: '#171d22',
        }),
        title: {
          marginLeft: 4,
          fontSize: 18,
          marginBottom: 0,
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: {
          marginTop: 4,
          lineHeight: 1,
        },
        value: {
          lineHeight: 1.5,
        },
      },
    },
  },
});

const quantumCyberpunk = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#e91a6c', // Neon green
      },
      secondary: {
        main: '#e91a6c', // Neon pink
      },
      error: {
        main: '#ff0033', // Neon red
      },
      warning: {
        main: '#ffcc00', // Bright yellow
      },
      info: {
        main: '#0066ff', // Bright blue
      },
      success: {
        main: '#00ff66', // Neon green
      },
      background: {
        default: '#1a1a1a', // Dark gray
        paper: '#333333', // Slightly lighter gray
      },
      banner: {
        info: '#0066ff',
        error: '#000000',
        text: '#ffffff', // White text
        link: '#ff0090', // Neon pink links
      },
      navigation: {
        background: '#000000', // Pitch black for a sleek feel
        indicator: '#e91a6c', // Bright yellow for active indicators
        color: '#fff', // Neon green for text
        selectedColor: '#e91a6c', // Neon pink for selected items
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: '"Courier New", monospace', // Monospace font for tech vibe
      h1: {
        fontSize: 54,
        fontWeight: 700,
        marginBottom: 10,
      },
      h2: {
        fontSize: 40,
        fontWeight: 700,
        marginBottom: 8,
      },
      h3: {
        fontSize: 32,
        fontWeight: 700,
        marginBottom: 6,
      },
      h4: {
        fontWeight: 700,
        fontSize: 28,
        marginBottom: 6,
      },
      h5: {
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 4,
      },
      h6: {
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 2,
      },
    },
  }),
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: () => ({
          display: 'flex',
          width: 'auto',
          height: '28px',
          backgroundColor: '#000000',
          backgroundImage: `url("/mesh.png")`,
          // backgroundImage: `linear-gradient(45deg, #000000 30%, #000000 90%)`,
        }),
        title: {
          marginLeft: 4,
          fontSize: 18,
          marginBottom: 0,
          color: '#ffffff',
        },
        subtitle: {
          margin: 10,
          display: 'none',
        },
        type: {
          margin: 10,
          display: 'flex',
        },
        leftItemsBox: {
          display: 'flex',

          alignItems: 'center',
        },
        rightItemsBox: {
          alignContent: 'stretch',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        selected: {
          '&$root': {
            color: '#fff',
          },
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: {
          marginTop: 4,
          lineHeight: 1,
          color: '#e91a6c', // Neon pink for labels
        },
        value: {
          lineHeight: 1.5,
          color: '#e91a6c', // Neon blue for values
        },
      },
    },
  },
});

const quantumCyberpunkLight = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#E6005C', // Neon green
      },
      secondary: {
        main: '#e91a6c', // Neon pink
      },
      error: {
        main: '#ff0033', // Neon red
      },
      warning: {
        main: '#ffcc00', // Bright yellow
      },
      info: {
        main: '#0066ff', // Bright blue
      },
      success: {
        main: '#00ff66', // Neon green
      },
      background: {
        default: '#FAF9F6', // Dark gray
        // paper: '#333333', // Slightly lighter gray
      },
      banner: {
        info: '#0066ff',
        error: '#000000',
        text: '#ffffff', // White text
        link: '#ff0090', // Neon pink links
      },
      navigation: {
        background: '#000000', // Pitch black for a sleek feel
        indicator: '#e91a6c', // Bright yellow for active indicators
        color: '#fff', // Neon green for text
        selectedColor: '#e91a6c', // Neon pink for selected items
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: '"Courier New", monospace', // Monospace font for tech vibe
      h1: {
        fontSize: 54,
        fontWeight: 700,
        marginBottom: 10,
      },
      h2: {
        fontSize: 40,
        fontWeight: 700,
        marginBottom: 8,
      },
      h3: {
        fontSize: 32,
        fontWeight: 700,
        marginBottom: 6,
      },
      h4: {
        fontWeight: 700,
        fontSize: 28,
        marginBottom: 6,
      },
      h5: {
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 4,
      },
      h6: {
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 2,
      },
    },
  }),
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: () => ({
          display: 'flex',
          width: 'auto',
          height: '28px',
          backgroundColor: '#000000',
          backgroundImage: `url("/mesh.png")`,
        }),
        title: {
          marginLeft: 4,
          fontSize: 18,
          marginBottom: 0,
          color: '#ffffff',
        },
        subtitle: {
          margin: 10,
          display: 'none',
        },
        type: {
          margin: 10,
          display: 'flex',
        },
        leftItemsBox: {
          display: 'flex',

          alignItems: 'center',
        },
        rightItemsBox: {
          alignContent: 'stretch',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        selected: {
          '&$root': {
            color: '#fff',
          },
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: {
          marginTop: 4,
          lineHeight: 1,
          color: '#ffffff', // Neon pink for labels
        },
        value: {
          lineHeight: 1.5,
          color: '#ffffff', // Neon blue for values
        },
      },
    },
  },
});

const quantumMatrix = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#00ff9f', // Neon green
      },
      secondary: {
        main: '#ff0090', // Neon pink
      },
      error: {
        main: '#ff0033', // Neon red
      },
      warning: {
        main: '#ffcc00', // Bright yellow
      },
      info: {
        main: '#0066ff', // Bright blue
      },
      success: {
        main: '#00ff66', // Neon green
      },
      background: {
        default: '#1a1a1a', // Dark gray
        paper: '#333333', // Slightly lighter gray
      },
      banner: {
        info: '#0066ff',
        error: '#ff0033',
        text: '#ffffff', // White text
        link: '#ff0090', // Neon pink links
      },
      navigation: {
        background: '#000000', // Pitch black for a sleek feel
        indicator: '#00ff9f', // Bright yellow for active indicators
        color: '#00ff9f', // Neon green for text
        selectedColor: '#ff0090', // Neon pink for selected items
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: '"Courier New", monospace', // Monospace font for tech vibe
      h1: {
        fontSize: 54,
        fontWeight: 700,
        marginBottom: 10,
      },
      h2: {
        fontSize: 40,
        fontWeight: 700,
        marginBottom: 8,
      },
      h3: {
        fontSize: 32,
        fontWeight: 700,
        marginBottom: 6,
      },
      h4: {
        fontWeight: 700,
        fontSize: 28,
        marginBottom: 6,
      },
      h5: {
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 4,
      },
      h6: {
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 2,
      },
    },
  }),
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: () => ({
          display: 'flex',
          width: 'auto',
          height: '28px',
          backgroundColor: '#000000', // Jet black header
          backgroundImage: `url("/matrix.gif")`,
          opacity: 0.7,
          backgroundSize: '40%', // Width at 50%, height at 50%
        }),
        title: {
          marginLeft: 4,
          fontSize: 18,
          marginBottom: 0,
          color: '#ffffff', // White text
        },
        subtitle: {
          margin: 10,
          display: 'none',
        },
        type: {
          margin: 10,
          display: 'flex',
        },
        leftItemsBox: {
          display: 'flex',

          alignItems: 'center',
        },
        rightItemsBox: {
          alignContent: 'stretch',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        selected: {
          '&$root': {
            color: '#fff',
          },
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: {
          marginTop: 4,
          lineHeight: 1,
          color: '#ffffff', // Neon pink for labels
        },
        value: {
          lineHeight: 1.5,
          color: '#ffffff', // Neon blue for values
        },
      },
    },
  },
});

const quantumFabriq = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#ffffff',
      },
      secondary: {
        main: '#b8b8b8',
      },
      error: {
        main: '#cc3333',
      },
      warning: {
        main: '#ffaa00',
      },
      info: {
        main: '#3a7ca5',
      },
      success: {
        main: '#4caf50',
      },
      background: {
        default: '#121212',
        paper: '#1e1e1e',
      },
      banner: {
        info: '#3a7ca5',
        error: '#cc3333',
        text: '#e0e0e0',
        link: '#b8b8b8',
      },
      navigation: {
        background: '#1b1b1b',
        indicator: '#606060',
        color: '#ffffff',
        selectedColor: '#b8b8b8',
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: '"Courier New", monospace',
      h1: {
        fontSize: 54,
        fontWeight: 700,
        marginBottom: 10,
      },
      h2: {
        fontSize: 40,
        fontWeight: 700,
        marginBottom: 8,
      },
      h3: {
        fontSize: 32,
        fontWeight: 700,
        marginBottom: 6,
      },
      h4: {
        fontWeight: 700,
        fontSize: 28,
        marginBottom: 6,
      },
      h5: {
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 4,
      },
      h6: {
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 2,
      },
    },
  }),
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: () => ({
          display: 'flex',
          width: 'auto',
          backgroundColor: '#000000',
          backgroundImage: `url("/fabriq.png")`,
        }),
        title: {
          marginLeft: 4,
          fontSize: 24,
          color: '#ffffff',
        },
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        selected: {
          '&$root': {
            color: '#b8b8b8',
          },
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: {
          marginTop: 4,
          lineHeight: 1,
          color: '#ffffff',
          fontWeight: 700,
        },
        value: {
          lineHeight: 1.5,
          color: '#ffffff',
          fontWeight: 700,
        },
      },
    },
  },
});

const solarizedDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#268bd2', // Solarized blue
      },
      secondary: {
        main: '#2aa198', // Solarized cyan
      },
      error: {
        main: '#dc322f', // Solarized red
      },
      warning: {
        main: '#b58900', // Solarized yellow
      },
      info: {
        main: '#839496', // Solarized base0 (light gray)
      },
      success: {
        main: '#859900', // Solarized green
      },
      background: {
        default: '#002b36', // Solarized dark (base03)
        paper: '#073642', // Solarized lighter dark (base02)
      },
      banner: {
        info: '#268bd2', // Solarized blue
        error: '#dc322f', // Solarized red
        text: '#839496', // Light gray (base0)
        link: '#2aa198', // Solarized cyan
      },
      navigation: {
        background: '#002b36', // Solarized base03
        indicator: '#586e75', // Muted gray (base01)
        color: '#93a1a1', // Light gray (base1)
        selectedColor: '#2aa198', // Solarized cyan
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: '"Courier New", monospace',
      h1: {
        fontSize: 54,
        fontWeight: 700,
        marginBottom: 10,
      },
      h2: {
        fontSize: 40,
        fontWeight: 700,
        marginBottom: 8,
      },
      h3: {
        fontSize: 32,
        fontWeight: 700,
        marginBottom: 6,
      },
      h4: {
        fontWeight: 700,
        fontSize: 28,
        marginBottom: 6,
      },
      h5: {
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 4,
      },
      h6: {
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 2,
      },
    },
  }),
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: () => ({
          display: 'flex',
          width: 'auto',
          backgroundColor: '#002b36',
          opacity: 0.9,
        }),
        title: {
          marginLeft: 4,
          fontSize: 26,
          color: '#268bd2',
        },
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        selected: {
          '&$root': {
            color: '#2aa198', // Solarized cyan
          },
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: {
          marginTop: 4,
          lineHeight: 1,
          color: '#93a1a1', // Solarized base1
        },
        value: {
          lineHeight: 1.5,
          color: '#839496', // Solarized base0
        },
      },
    },
  },
});

export {
  solarizedDarkTheme,
  quantumLight,
  quantumDark,
  quantumFabriq,
  quantumMatrix,
  quantumCyberpunk,
  quantumCyberpunkLight,
};
