import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const qmExcellencePlugin = createPlugin({
  id: 'qm-excellence',
  routes: {
    root: rootRouteRef,
  },
});

export const QmExcellencePage = qmExcellencePlugin.provide(
  createRoutableExtension({
    name: 'QmExcellencePage',
    component: () =>
      import('./components/ExcellencePage').then(m => m.ExcellencePage),
    mountPoint: rootRouteRef,
  }),
);

export const QmGroupExcellenceCard = qmExcellencePlugin.provide(
  createRoutableExtension({
    name: 'QmGroupExcellenceCard',
    component: () =>
      import('./components/AggregateScoreCards/GroupExcellenceCard').then(
        m => m.GroupExcellenceCard,
      ),
    mountPoint: rootRouteRef,
  }),
);
