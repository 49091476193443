import React, { useState, useEffect, useCallback } from 'react';
import { Typography, LinearProgress, Grid } from '@material-ui/core';
import ActionCenterPanel from './ActionCenterPanel';
import TestExecutionTable from './TestExecutionTable';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import type { Result } from '../../utils/types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DateRangeSelectorComponent from '../Shared/DateRangeSelectorComponent';
import PipelineTypeSelectorComponent from '../Shared/PipelineTypeSelectorComponent';
import { EmptyStateComponent } from './EmptyStateComponent';
import { HelpDialogComponent } from '../Shared/HelpDialogComponent';
import { useDateRange } from '../Shared/DateRangeContext';
import { usePipelineType } from '../Shared/PipelineTypeProvider';
import OverviewSummaryPanel from './OverviewSummaryPanel';

export const TestMetricOverviewLayout = () => {
  const [info, setInfo] = useState<Result>();
  const [allInfo, setAllInfo] = useState<Result[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { entity } = useEntity();
  const { dateRange } = useDateRange();
  const [pipelineType] = usePipelineType();

  const repo = entity.metadata.annotations?.['gitlab.com/project-slug']
    ?.split('/')
    .pop();
  const { fetch } = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const fetchData = useCallback(async () => {
    try {
      const testMetricsUrl = `${await discoveryApi.getBaseUrl(
        'proxy',
      )}/qm-test-metrics`;

      const response = await fetch(
        `${testMetricsUrl}/results/${repo}?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&pipelineType=${pipelineType}`,
      );
      const data = await response.json();
      const modifiedData: Result = {
        ...data.summary,
        passRate: data.summary.passRate * 100,
        avgDuration: data.summary.avgDuration,
      };

      setInfo(modifiedData);
      setAllInfo(data.tests);

      if (data && data.tests?.length > 0) {
        setDataLoaded(true);
      } else {
        setDataLoaded(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [
    discoveryApi,
    fetch,
    repo,
    dateRange.startDate,
    dateRange.endDate,
    pipelineType,
  ]);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, [dateRange, fetchData]);

  return (
    <>
      <Grid container spacing={3} direction="column">
        {loading && <LinearProgress />}
        {!loading && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h5">Test Metrics</Typography>
              <InfoOutlinedIcon fontSize="small" onClick={handleOpenDialog} />
            </div>
            <Grid
              container
              spacing={0}
              item
              xs={12}
              direction="row"
              justifyContent="space-between"
            >
              <PipelineTypeSelectorComponent />
              <DateRangeSelectorComponent />
            </Grid>
            {dataLoaded ? (
              <>
                <OverviewSummaryPanel
                  successRate={info?.passRate || 0}
                  avgDuration={info?.avgDuration || 0}
                  executions={info?.total || 0}
                  allInfo={allInfo}
                />
                <ActionCenterPanel allInfo={allInfo} />
                <TestExecutionTable allInfo={allInfo} />
              </>
            ) : (
              <EmptyStateComponent />
            )}
          </>
        )}
        <HelpDialogComponent
          dialogOpen={dialogOpen as boolean}
          onClose={handleCloseDialog}
        />
      </Grid>
    </>
  );
};
